import React, {Dispatch, useContext} from 'react'
import {
    columnsContainsWater,
    getExtraction,
    playFieldReducer,
    PlayFieldState,
    PlayFieldStateAction,
    toConvertSize
} from './playFieldState'
import ViewBlock from './ViewBlock'
import ProductLookUpResponse from '../../controllers/ProductLookUpResponse'
import ElementValues, {FormatValue, getValue, measureValue} from './Helpers'
import LeafSummary from './LeafSummary'
import SoilSummary from './SoilSummary'
import Withdrawal from './Withdrawal'
import AppContext from '../../appContext'
import PlayfieldTemplate from './PlayfieldTemplate'
import { PlayFieldExtra } from './Program'
import { customUnits, unitCustom } from './CustomUnits'
import PhenologyBlock from "./PhenologyBlock";
import PlayFieldProgram from "../../controllers/PlayFieldProgram";
import GenerateBy from "../../controllers/GenerateBy";
import Notes from "./Notes";

const PlayField: React.FC<{
    extra: PlayFieldExtra;
    setExtra: (extra: Partial<PlayFieldExtra>, state?: PlayFieldState) => void;

    state: PlayFieldState;
    stateDispatch: Dispatch<PlayFieldStateAction>;

    products: ProductLookUpResponse[];

    program: PlayFieldProgram
    addProduct: (product: ProductLookUpResponse, applyToAll: boolean) => void;
    editProduct: (columnIndex: number, product: ProductLookUpResponse, applyToAll: boolean) => void;
}> = (props) => {
    const { initial: { system }, word } = useContext(AppContext)

    function saveNotes(note: string) {
        props.setExtra({ notes: note })
    }
    
    const columns = props.state.calculations.pricingColumns;
    
    function updateGenerateBy(generateBy: GenerateBy) {
        props.setExtra({ viewBlock: { ...props.extra.viewBlock, generateBy} })
    }
    
    return <div className="mx-3 mb-1 border-2 border-gray-100">

        <LeafSummary data={props.extra.leaf} setData={(leaf) => props.setExtra({ leaf })}/>
        <SoilSummary data={props.extra.ground} setData={(ground) => props.setExtra({ ground })}/>

        <PlayfieldTemplate
            state={[props.state, props.stateDispatch]}
            products={props.products}
            program={props.program}
            addProduct={props.addProduct}
            editProduct={props.editProduct}
            currentGenerateBy={props.extra.viewBlock.generateBy}
            updateGenerateBy={(generateBy) => updateGenerateBy(generateBy)}

            render={{
                viewBlock: () => <ViewBlock farmName={props.program.farmName} data={props.extra.viewBlock} waterUsed={columnsContainsWater(props.state.data.columns)} update={viewBlock => props.setExtra({ viewBlock }, playFieldReducer(props.state, { type: 'recalculateCells', convertSize: toConvertSize(viewBlock) }))}/>,
                extractions: (rowIndex) => <ElementValues system={system} values={getExtraction(props.state, rowIndex)} emptyColor="text-white"/>,
                totals: () => (<>
                    <tr className="bg-gray-300 font-bold">
                        <td className="border bg-1"/>
                        <td className="border bg-3 text-gray-800">{word('total')} {unitCustom(system, customUnits.kgha_lbAc)}</td>

                        {columns.map((column, i) => {

                                if (column.type == 'column') {
                                    const s = props.state.calculations.standardTotals[column.index] ?? measureValue(0, 0);
                                    return <td key={i} className="border">
                                        <FormatValue emptyColor="text-gray-300" value={getValue(system, s)}
                                                     decimalPlaces={1}/>
                                    </td>
                                }
                                // don't render the pricing col if the pricingModule is disabled
                                if (!props.program?.pricingModule) return null
                             
                                return <td key={i}>.</td>
                        })}

                        <ElementValues system={system} values={props.state.calculations.elementStdTotals}
                            emptyColor="text-gray-300"/>

                    </tr>

                    {/* <tr className="bg-gray-300 font-bold"> */}
                    {/*     <td className="border bg-1"/> */}
                    {/*     <td className="border bg-3 text-gray-800">{word('total')} {unitCustom(system, customUnits.lHa_gallonAc)}</td> */}

                    {/*     {props.state.calculations.liquidTotals.map((s, i) => ( */}
                    {/*         <td key={i} className="border"> */}
                    {/*             <FormatValue emptyColor="text-gray-300" value={getValue(system, s)}/> */}
                    {/*         </td> */}
                    {/*     ))} */}

                    {/* </tr> */}

                    <tr className="bg-gray-300 font-bold">
                        <td className="border bg-1"/>
                        <td className="border bg-3 text-gray-800">
                            {word('total')} {unitCustom(system, customUnits.kgLand_lbLand)}
                        </td>

                        {columns.map((column, i) => {

                            if (column.type == 'column') {
                                const s = props.state.calculations.landTotals[column.index] ?? measureValue(0, 0);
                                return <td key={i} className="border">
                                    <FormatValue emptyColor="text-gray-300" value={getValue(system, s)}
                                                 decimalPlaces={1}/>
                                </td>
                            }
                            // don't render the pricing col if the pricingModule is disabled
                            if (!props.program?.pricingModule) return null

                            return <td key={i}>.</td>
                        })}
                        
                        <ElementValues system={system} values={props.state.calculations.elementLandTotals}
                            emptyColor="text-gray-300"/>
                    </tr>
                </>)
            }}
        />

        <div className="flex mt-2">
            <Withdrawal system={system} viewBlock={props.extra.viewBlock} elementTotals={props.state.calculations.elementLandTotals}/>
            {
                props.program.tableGrapes
                    ? <PhenologyBlock extractions={props.state.calculations.extractions} />
                    : null
            }
        </div>
            <Notes notes={props.extra.notes} setNotes={(note) => saveNotes(note)}/>
    </div>
}

export default PlayField
