import ProductLookUpResponse from '../../controllers/ProductLookUpResponse'
import { convertKgPerHa, ConvertData, ConvertSize, flipSystem, UnitForm, UnitOptions } from './units'
import JsonPlayField from '../../controllers/JsonPlayField'
import Column from '../../controllers/Column'
import MeasureValue from '../../controllers/MeasureValue'
import { create2dArray } from '../../immutableState'
import MeasurementUnit from '../../controllers/MeasurementUnit'
import Cell from '../../controllers/Cell'
import ProductUnit from '../../controllers/ProductUnit'
import { ProductReference } from './playFieldState'
import { inRange } from '../../controllers/helper'
import ProductColumn from "../../controllers/ProductColumn";
import PricingColumn from "../../controllers/PricingColumn";
import {getValue} from "./Helpers";

// In template mode we are not doing any calculations
// but do need a convertSize to pass into
export const emptyConvertSize: ConvertSize = {
    size: {
        ac: 1,
        ha: 1
    },
    trees: 1,
    water: {
        lHa: 1,
        galAc: 1
    }
}

export function getMeasureValue(value: MeasureValue, system: MeasurementUnit): number {
    return system == MeasurementUnit.METRIC
        ? value.metric
        : value.imperial;
}


export function productToFrom (product: ProductLookUpResponse): UnitForm {
    if (product.solid && product.liquid) {
        return UnitForm.Both
    }
    return product.solid ? UnitForm.Solid : UnitForm.Liquid
}

export function isProductColumn(c: Column): c is ProductColumn {
    return c.type === 'product'
}

export function isPricingColumn(c: Column): c is PricingColumn {
    return c.type === 'pricing'
}


// link actual product to column. List of lookup products from initial request
export function columnsToProductLookupResp (columns: Column[], system: MeasurementUnit, productLookup: (productId: number) => ProductLookUpResponse): ProductReference[] {

    return columns
        .filter(isProductColumn)
        .map((column, index) => {
            const product = productLookup(column.productId)
            
            const metricOptions = UnitOptions[MeasurementUnit.METRIC][productToFrom(product)]
            const imperialOptions = UnitOptions[MeasurementUnit.IMPERIAL][productToFrom(product)]

            if (metricOptions[0] && imperialOptions[0]) {
                const hasMetric = metricOptions.some(l => l.unit == column.unit)
                const hasImperial = imperialOptions.some(l => l.unit == column.imperialUnit)

                // Change column unit to the first available unit if not exist.
                column.unit = hasMetric ? column.unit : metricOptions[0].unit
                column.imperialUnit = hasImperial ? column.imperialUnit : imperialOptions[0].unit
            }

            return {
                ...column,
                product,
                // initial key is the index
                key: index,
                priceEdit: column.price ? getValue(system, column.price).toFixed(2) : ''
            }
        })
}

// style each individual cell on the table according to focused position
// because we use collapsed table border each border clash with it's siblings
export interface CellPosition {
    x: number;
    y: number;
}

export function borderStyle ({
    x: cx,
    y: cy
}: CellPosition, selected: CellPosition | null): string {
    if (!selected) {
        return 'border'
    }
    const {
        x: sx,
        y: sy
    } = selected

    if (cx === sx && cy === sy) {
        return 'border border-dashed border-primary-600 dash-animation'
    }

    // the cell to the left
    if (cx === sx - 1 && cy === sy) {
        return 'border-t border-l border-b'
    }

    // the cell to the right
    if (cx === sx + 1 && cy === sy) {
        return 'border-t border-r border-b'
    }

    // the cell to the top
    if (cx === sx && cy === sy - 1) {
        return 'border-t border-l border-r'
    }

    // the cell to the bottom
    if (cx === sx && cy === sy + 1) {
        return 'border-b border-l border-r'
    }

    // else the normal border
    return 'border'
}

export function cellColor({x: cx, y: cy}: CellPosition, cells: CellType[][], add: string = "", defaultColor: string = "#ffffff"): string {
    const cell = get2dArray(cells, cx, cy)
    if (!cell) {
        return defaultColor
    }
    return (cell.color ?? defaultColor) + add;
}

export interface CellType {
    // bound to the input text
    value: string;
    // original user input
    // keep both metric and imperial to avoid fraction loss
    user: MeasureValue;
    // the user data converted to a standardize form to make calculations possible
    // measure in kg/ha or lb/ac
    standard: MeasureValue;
    // if liquid convert to l/ha or gal/ac
    liquid: MeasureValue;
    // add an optional cell highlight color
    color?: string;
}
function measureValue (metric: number, imperial: number): MeasureValue {
    return {
        metric,
        imperial
    }
}

function create2dCellType (cols: number, rows: number): CellType[][] {
    return create2dArray<CellType>(cols, rows, () => ({
        user: measureValue(0, 0),
        standard: measureValue(0, 0),
        liquid: measureValue(0, 0),
        value: '',
        color: undefined
    }))
}

export function get2dArray<T>(array:T[][], col: number, row: number): T | null {
    const rows = array[col];
    if (!rows)
        return null;
    
    const cell = rows[row]
    if (!cell)
        return null;
    
    return cell;
}

export function set2dArray<T>(array: T[][], col: number, row: number, fill: T): void {
    // check if in range
    if (!inRange(col, array))
        return;
    
    const rows = array[col]
    if (!rows || !inRange(row, rows)) 
        return  

    rows[row] = fill;
}

export function cellsToArray (playField: JsonPlayField, system: MeasurementUnit): CellType[][] {
    const ret: CellType[][] = create2dCellType(playField.columns.length, playField.rows.length)

    // now fill the 2d array
    for (const cell of playField.cells) {
        const cellValue: CellType = {
            user: cell.user,
            standard: cell.standard,
            liquid: measureValue(0, 0),
            value: formatValue(system === MeasurementUnit.METRIC ? cell.user.metric : cell.user.imperial),
            color: cell.color ?? undefined
        };
        set2dArray(ret, cell.col, cell.row, cellValue);
    }
    return ret
}

// convert cells back to original representation as stored in the db
export function convertCells (cells: CellType[][]): Cell[] {
    const ret: Cell[] = []
    for (let c = 0; c < cells.length; c++) {
        for (let r = 0; r < cells[c]!.length; r++) {
            const cell = cells[c]
            if (!cell || !cell[r]) {
                continue
            }

            const { user, standard, color } = cell[r]!
            // ignore 0 values
            if (user.metric === 0 && user.imperial === 0) {
                continue
            }
            ret.push({
                user,
                standard,
                row: r,
                col: c,
                color: color ?? null
            })
        }
    }
    return ret
}

export function getCellSafe (arr: CellType[][], row: number, column: number): CellType {
    const col = arr[column]
    if (!col) {
        return emptyCellType()
    }

    const cell = col[row]
    if (!cell) {
        return emptyCellType()
    }

    return cell
}

export function getCellValue (cell: CellType, system: MeasurementUnit): number {
    return system === MeasurementUnit.METRIC
        ? cell.user.metric
        : cell.user.imperial
}

export function getCellStandard (cell: CellType, system: MeasurementUnit): number {
    return system === MeasurementUnit.METRIC
        ? cell.standard.metric
        : cell.standard.imperial
}

export function emptyCellType (): CellType {
    return ({
        user: measureValue(0, 0),
        standard: measureValue(0, 0),
        liquid: measureValue(0, 0),
        value: '',
        color: undefined
    })
}

// Willem wants consistency.
// The client will in 5 weeks asks for inconsistency.
export function formatValue (value: number, decimalPlaces:number = 1): string {
    return value === 0 ? '' : value.toFixed(decimalPlaces)
}

export function recalculateCell (
    cell: CellType,
    system: MeasurementUnit,
    header: ProductReference,
    convertData: ConvertData,
    valueCallback?: number,
    inputValue?: string
): CellType {

    const unit = system === MeasurementUnit.METRIC ? header.unit : header.imperialUnit;
    const value = valueCallback !== undefined ? valueCallback : getCellValue(cell, system)

    // convert num to the opposite measure unit
    const original = {
        value,
        unit
    }
    const flipped = flipSystem(original, convertData)

    const stdMetric = convertKgPerHa(original, ProductUnit.KgHa, convertData)
    const stdImperial = convertKgPerHa(flipped, ProductUnit.LbAc, convertData)

    const liquid = measureValue(0, 0);

    return system === MeasurementUnit.METRIC
        ? {
            value: inputValue !== undefined ? inputValue : formatValue(value),
            user: measureValue(value, flipped.value),
            standard: measureValue(stdMetric.value, stdImperial.value),
            liquid,
            color: cell.color
        }
        : {
            value: inputValue !== undefined ? inputValue : formatValue(value),
            user: measureValue(flipped.value, value),
            standard: measureValue(stdMetric.value , stdImperial.value),
            liquid,
            color: cell.color
        }
}
