import React, {Dispatch, useContext, useEffect, useMemo, useRef, useState} from 'react'
import AppContext from '../../appContext'
import {defaultUnit, unitDisplay, UnitOptions} from './units'
import {
    PlayFieldColumn,
    PlayFieldColumnPricing,
    PlayFieldState,
    PlayFieldStateAction
} from './playFieldState'
import ProductLookUpResponse from '../../controllers/ProductLookUpResponse'
import Dialog from '../../components/Dialog'
import ProductLookup from './ProductLookup'
import ProductUnit from '../../controllers/ProductUnit'
import {classNames} from '../../wrapper'
import {borderStyle, cellColor, CellPosition, getCellSafe, productToFrom} from './Functions'
import MeasurementUnit from '../../controllers/MeasurementUnit'
import Warning, {useWarningState} from './Warning'
import {arrayPush} from '../../immutableState'
import useDrag from './drag'
import {getValue, measureValue} from "./Helpers";
import PlayFieldProgram from "../../controllers/PlayFieldProgram";
import {matchMonth, matchWeek, months, weeks} from "./months";
import Row from "../../controllers/Row";
import SelectStringNullable from "../../components/SelectStringNullable";
import { Bars2Icon } from '@heroicons/react/24/solid'
import NumberNullable from "../../components/NumberNullable";
import GenerateBy from "../../controllers/GenerateBy";

function getNewPosition (cell: CellPosition, event: KeyboardEvent, rows: number, cols: number): CellPosition {
    switch (event.key) {
    case 'ArrowUp':
        return { x: cell.x, y: cell.y > 0 ? cell.y - 1 : rows - 1 }
    case 'ArrowDown':
    case 'Enter':
        return { x: cell.x, y: cell.y < rows - 1 ? cell.y + 1 : 0 }
    case 'ArrowLeft':
        return { x: cell.x > 0 ? cell.x - 1 : cols - 1, y: cell.y }
    case 'ArrowRight':
        return { x: cell.x < cols - 1 ? cell.x + 1 : 0, y: cell.y }
    }
    return cell
}

export function usePlayFieldProduct (playFieldDispatch: Dispatch<PlayFieldStateAction>) {
    const [products, setProducts] = useState<ProductLookUpResponse[]>([])
    const context = useContext(AppContext)

    function addProductIfNotExists (add: ProductLookUpResponse) {
        if (products.findIndex(p => p.id === add.id) === -1) { setProducts(arrayPush(products, add)) }
    }

    function addProduct (product: ProductLookUpResponse) {
        addProductIfNotExists(product)
        playFieldDispatch({
            type: 'addColumn',
            product,
            unit: defaultUnit(context.initial.system, product.solid),
            color: '#EDF2F7',
            system: context.initial.system
        })
    }

    function editProduct (column: number, product: ProductLookUpResponse) {
        addProductIfNotExists(product)
        playFieldDispatch({
            type: 'updateColumn',
            index: column,
            product
        })
    }

    return {
        addProduct,
        editProduct,
        products,
        setProducts
    }
}

function pricingByCategory(system: MeasurementUnit, pricingColumns: PlayFieldColumn[]) {
    return pricingColumns.reduce((acc, cur, index) => {
        if (cur.type === 'pricing') {
            const lastIndex = acc.reduce((acc, cur) => acc + cur.colSpan, 0);
            const prevIndex = acc.length > 0 ? lastIndex : 0;
            acc.push({
                colSpan: index - prevIndex,
                data: cur.data,
                content: getValue(system, cur.data.total).toFixed(2)
            })
            acc.push({colSpan: 1, data: cur.data, content: null})
        }
        return acc
    }, [] as {colSpan: number, content: string | null, data: PlayFieldColumnPricing}[]);
}

const PlayFieldTemplate: React.FC<{
    state: [PlayFieldState, Dispatch<PlayFieldStateAction>];
    products: ProductLookUpResponse[];
    addProduct: (product: ProductLookUpResponse, applyToAll: boolean) => void;
    editProduct: (columnIndex: number, product: ProductLookUpResponse, applyToAll: boolean) => void;
    updateGenerateBy: (generateBy: GenerateBy) => void
    currentGenerateBy: GenerateBy
    // render options to control template / full mode
    render?: {
        extractions: (rowIndex: number) => React.ReactNode;
        totals: () => React.ReactNode;
        viewBlock: () => React.ReactNode;
    }
    program?: PlayFieldProgram
}> = (props) => {
    
    const { initial: { system } } = useContext(AppContext)
    const [showProduct, setShowProduct] = useState(false)
    const [state, stateDispatch] = props.state
    const [selectedCell, setSelectedCell] = useState<CellPosition | null>(null)
    const productEditIndex = useRef<number | null>(null)
    const context = useContext(AppContext)
    const confirmation = useWarningState<number>(0)
    const [regionId, setRegionId] = useState<number | null>(context.initial.regions[0] ?? null)
    
    const columns = state.calculations.pricingColumns;
    const pricingColspanColumns = useMemo(() => pricingByCategory(system, columns), [columns]);

    function addProduct () {
        productEditIndex.current = null
        setShowProduct(true)
    }

    function editProduct (columnIndex: number) {
        productEditIndex.current = columnIndex
        setShowProduct(true)
    }

    function productSelected (product: ProductLookUpResponse, applyToAll: boolean) {
        setShowProduct(false)

        if (productEditIndex.current === null) {
            props.addProduct(product, applyToAll)
            return
        }

        props.editProduct(productEditIndex.current, product, applyToAll)
    }

    function getCellValue (row: number, column: number) {
        return getCellSafe(state.data.cells, row, column).value
    }

    function handleKeyDown (event: KeyboardEvent) {
        // we don't have access to selectedCell directly because it was created with initial state
        // see: https://stackoverflow.com/questions/55265255/react-usestate-hook-event-handler-using-initial-state
        const d = props.state[0].data
        const inputs = Array.prototype.slice.call(document.querySelectorAll<HTMLInputElement>('.playfield-input'))
        const index = inputs.findIndex(f => f === document.activeElement)
        if (index === -1) { return }

        // determine x, y for current focused element
        const current = { y: Math.trunc(index / d.columns.length), x: index % d.columns.length }
        const newValue = getNewPosition(current, event, d.rows.length, d.columns.length)
        // convert newValue back to index
        const newIndex = newValue.y * d.columns.length + newValue.x

        if (current.x !== newValue.x || current.y !== newValue.y) {
            inputs[newIndex]!.focus()
            inputs[newIndex]!.select()
            event.preventDefault()
        }
    }

    // attach keydown event with props.state in scope
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown)
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [props.state])

    const fullMode = props.render !== undefined

    function focusBlurCell (state: 'focus' | 'blur', col: number, row: number) {
        if (state === 'blur') {
            setSelectedCell(null)
            stateDispatch({ type: 'blurCellValue', row, column: col })
        }
        if (state === 'focus') {
            setSelectedCell({ x: col, y: row })
        }
    }

    function columnRemoveWarning (columnIndex: number) {
        const name = state.data.columns[columnIndex] === null ? '' : productName(state.data.columns[columnIndex]?.product!)

        confirmation.show(`${context.word('removing')} ${name}?`, columnIndex)
    }

    function removeColumn (columnIndex: number) {
        stateDispatch({
            type: 'removeColumn',
            index: columnIndex
        })
        confirmation.hide()
    }

    function productName (product: ProductLookUpResponse) {
        return product.nameKey !== null ? context.word(product.nameKey) : product.name
    }
    
    //Return the common regions of the products 
    const allRegions =  state.data.columns.map(c => c.product.regionsKeys)
    
    const common = allRegions.reduce<string[]>((acc, current, index) => {
        if (index == 0)
            return current;
        
        if (acc.length == 0)
            return [];
        
        return acc.filter(a => current.includes(a));
    }, []);

    const drag = useDrag<number>('drag-product', (target, destination) => stateDispatch({
        type: 'moveColumn',
        from: target,
        to: destination
    }))

    function generateWeeks() {
        props.updateGenerateBy(GenerateBy.Weekly)
    }
    
    function generateMonths() {
        props.updateGenerateBy(GenerateBy.Monthly)
    }
    
    function currencyCells() {
        if (!props.program?.pricingModule)
            return null;
        
        return <>
            <tr>
                <td></td>
                <td>
                    {`${context.word("cost")} ${props.program.currency?.symbol}/${context.wordUnit("kg", "lb")}`}
                </td>
                {columns.map((column, index) => {
                    if (column.type == 'column') {
                        return <td className="border whitespace-nowrap" key={column.data.key}>
                            <div className={"flex justify-between items-center px-1"}>
                                <div
                                    className={'pr-1'}>{column.data.price != null ? props.program?.currency?.symbol : ""}</div>
                                <div>

                                    <NumberNullable fractionDigits={2}
                                                    value={column.data.price ? getValue(system, column.data.price) : null}
                                                    change={f => {
                                                        stateDispatch({
                                                            type: 'updateColumn',
                                                            index: column.index,
                                                            price: f ?? 0,
                                                        })
                                                    }}>{(attrs) =>

                                        <input className="outline-none bg-white w-full text-right"
                                               {...attrs}
                                        />
                                    }
                                    </NumberNullable>


                                </div>
                            </div>
                        </td>
                    }
                    return <td key={column.data.color + index}></td>
                })}
            </tr>
            <tr>
                <td></td>
                <td>
                    {`${context.word("cost")}/${context.wordUnit("ha", "ac")} ${context.word("per")} ${context.word("product")}`}
                </td>
                {state.calculations.costPerProduct.map((column, index) => {
                    return <td className="border whitespace-nowrap" key={index}>
                        <div
                            className={classNames("flex justify-between items-center px-1", index == state.calculations.costPerProduct.length ? "bg-[#8B4513] text-white" : "")}>
                            {
                                column != null
                                    ? <>
                                        <div className={'pr-1'}>{props.program?.currency?.symbol}</div>
                                        <div>
                                            {getValue(system, column).toFixed(2)}
                                        </div>
                                    </>
                                    : null
                            }
                        </div>
                    </td>
                })}
            </tr>
            <tr>
                <td></td>
                <td>{
                    `${context.word("total")} ${context.word("cost")}/${context.wordUnit("ha", "ac")} ${context.word("per")} ${context.word("category")}`
                }</td>
                {
                    pricingColspanColumns.map(c => {
                            return <td colSpan={ c.colSpan } 
                                       className={classNames(``)}
                                       style={c.content ? {backgroundColor: c.data.color, color: c.data.textColor} : {}}
                            ><div>
                                {
                                    c.content == null
                                        ? null
                                        : <div className="flex justify-between items-center px-1">
                                            <div className={'pr-1'}>{props.program?.currency?.symbol}</div>
                                            {c.content}
                                        </div>
                                }
                            </div>
                            </td>
                    })
                }
            </tr>
            <tr>
                <td></td>
                <td>
                    {`${context.word("total")} ${context.word("cost")}/${context.wordUnit("ha", "ac")}`}
                </td>
                {
                    <td colSpan={columns.length - 1}
                        className="border whitespace-nowrap bg-[#8B4513] text-white">
                        <div className="flex justify-center items-center">
                            <div className={'pr-1'}>{props.program?.currency?.symbol}</div>
                            {getValue(system, state.calculations.pricingTotal || measureValue(0, 0)).toFixed(2)}
                        </div>
                    </td>
                }
                <td className="border whitespace-nowrap bg-[#8B4513] text-white">
                    <div className="flex justify-center items-center">
                        <div className={'pr-1'}>{props.program?.currency?.symbol}</div>
                        {getValue(system, state.calculations.pricingTotal || measureValue(0, 0)).toFixed(2)}
                    </div>
                </td>
            </tr>
        </>
    }


    return (
        <div>
            <Dialog show={showProduct} setShow={setShowProduct} title={context.word('choose_product')}
                    body={<ProductLookup
                        productSelected={productSelected}
                        selectedRegionId={regionId}
                        setSelectedRegionId={setRegionId}
                    />}/>

            <Warning state={confirmation} onYes={removeColumn}/>

            <div className="bg-white overflow-y-auto">
                <table className="text-sm w-full border-collapse">
                    <thead>
                    {common.length <= 0 && state.data.columns.length > 0 ? <tr>
                        <th colSpan={2}></th>
                        <th className='text-xs text-red-500'
                            colSpan={state.data.columns.length}>{context.word('Warning: products across regions')}</th>
                    </tr> : null}
                    <tr>
                        <th className="bg-gray-200 font-normal text-left relative" colSpan={2}>
                            {props.render?.viewBlock()}
                        </th>


                        {columns.map((column, index) => {
                                if (column.type == 'column') {
                                    const columnIndex = column.index;
                                    return <th key={column.data.key}
                                               {...drag.targetAndDest(columnIndex)}
                                               className={classNames('border-l border-r rotate text-xs  text-gray-800 truncate',
                                                   // drag. ? 'border-l-4 border-l-gray-500' : '',
                                                   drag.currentDrag !== null && drag.isOver(columnIndex) && drag.currentDrag < columnIndex ? 'border-r-4 border-gray-500' : '',
                                                   drag.currentDrag !== null && drag.isOver(columnIndex) && drag.currentDrag > columnIndex ? 'border-l-4 border-gray-500' : '',
                                                   drag.currentDrag === columnIndex ? 'opacity-50' : '')}
                                               title={
                                                   (context.word("sg_value") + ": " + column.data.product.sg as any as string ?? '')
                                                   + ' \n (' + column.data.product.regionsKeys.map(r => context.word(r)).join(', ') + ')'
                                               }
                                               style={{backgroundColor: column.data.hexColor ? column.data.hexColor : '#EDF2F7', color: column.data.textColor}}>
                                        <div>
                                            <span>{productName(column.data.product)}</span>
                                        </div>
                                    </th>
                                }
                                // don't render the pricing col if the pricingModule is disabled
                                if (!props.program?.pricingModule) return null

                                return <th className={classNames('border-l border-r rotate text-xs  text-gray-800 truncate')}
                                           style={{backgroundColor: column.data.color ? column.data.color : '#EDF2F7', color: column.data.textColor}}
                                           key={index + column.data.color}
                                >
                                    <div>
                                        <span>{context.word(column.data.title)}</span>
                                    </div>
                                </th>
                            }
                        )}

                        <th className={classNames('text-gray-600 text-center align-bottom', fullMode ? 'bg-gray-300' : 'bg-white')}
                            colSpan={6}>
                            <div
                                onClick={() => addProduct()}
                                className="float-left w-8 h-8 text-xl rounded-full text-white text-center cursor-pointer bg-primary-500 shadow hover:bg-primary-600">+
                            </div>
                            {/*}*/}

                            {fullMode ?
                                <span>{unitDisplay(system, ProductUnit.KgHa, ProductUnit.LbAc)}</span> : null}
                        </th>

                        {fullMode
                            ? <th className="bg-gray-400 text-gray-600 text-center align-bottom"
                                  colSpan={5}>{unitDisplay(system, ProductUnit.GHa, ProductUnit.OzAc)}</th>
                            : null
                        }
                    </tr>

                    <tr>
                        <th className="bg-gray-200 text-gray-800 text-center">{context.word('phenologically')}</th>
                        <th className="bg-gray-200 text-gray-800 text-center">
                            <div className="flex items-center justify-center">
                                {context.word('month')}
                                <select
                                    className="w-[25px] h-[25px] mx-2 bg-transparent group-hover:bg-gray-200 bg-no-repeat appearance-none"
                                    value={props.currentGenerateBy} 
                                    style={{backgroundImage: 'url(/images/TableSettings.svg)'}}
                                    onChange={e => {
                                        if (e.target.value === GenerateBy.None) {
                                            props.updateGenerateBy(GenerateBy.None)
                                        } else if (e.target.value === GenerateBy.Weekly) {
                                            generateWeeks()
                                        } else {
                                            generateMonths()
                                        }
                                    }}>
                                    <option value={GenerateBy.None}>{context.word(GenerateBy.None)}</option>
                                    <option value={GenerateBy.Weekly}>{context.word(GenerateBy.Weekly)}</option>
                                    <option value={GenerateBy.Monthly}>{context.word(GenerateBy.Monthly)}</option>
                                </select>
                            </div>
                        </th>

                        {columns.map((column, index) => {

                            if (column.type == 'column') {
                                const columnIndex = column.index;
                                return <th key={column.data.key} className="border-l border-r text-xs text-gray-800">
                                    <div>
                                        <input className='bg-white'
                                               type="color"
                                               list="presetColors"
                                               value={column.data.hexColor.length > 0 ? column.data.hexColor : '#EDF2F7'}
                                               onChange={e => {
                                                   stateDispatch({
                                                       type: 'updateColumn',
                                                       index: columnIndex,
                                                       color: (e.target as HTMLInputElement).value
                                                   })
                                               }}
                                               onBlur={e => {
                                                   stateDispatch({
                                                       type: 'updateColumn',
                                                       index: columnIndex,
                                                       color: (e.target as HTMLInputElement).value
                                                   })
                                               }}
                                        />
                                        <datalist id="presetColors">
                                            <option value="#cbaf97"/>
                                            <option value="#93d9f0"/>
                                            <option value="#8abe3d"/>
                                            <option value="#a16207"/>
                                            <option value="#c084fc"/>
                                            <option value="#EDF2F7"/>
                                        </datalist>
                                    </div>
                                    <div>
                                        <div
                                            className="inline-block align-middle text-green-500 mx-1 cursor-pointer"
                                            onClick={() => editProduct(columnIndex)}>{context.word('edit')}</div>
                                        <div onClick={() => columnRemoveWarning(columnIndex)}
                                             className="inline-block align-middle text-red-500 mx-1 cursor-pointer">X
                                        </div>
                                    </div>

                                    <select
                                        value={system === MeasurementUnit.METRIC ? column.data.unit : column.data.imperialUnit}
                                        className="outline-none"
                                        onInput={e => {
                                            stateDispatch({
                                                type: 'updateColumn',
                                                index: columnIndex,
                                                unit: {
                                                    system,
                                                    value: (e.target as HTMLInputElement).value as ProductUnit
                                                }
                                            })
                                        }}>

                                        {
                                            UnitOptions[system][productToFrom(column.data.product)]
                                                .filter(unitOption => unitOption.unit !== 'Gallon' && unitOption.unit !== 'Lb' && unitOption.unit !== 'Liter' && unitOption.unit !== 'Kg')
                                                .map(o => (<option key={o.unit}
                                                                   value={o.unit}>{context.word(o.display)}</option>))
                                        }
                                    </select>

                                </th>
                            }
                            // don't render the pricing col if the pricingModule is disabled
                            if (!props.program?.pricingModule) return null
                            
                            return <th 
                                style={{backgroundColor: column.data.color, color: column.data.textColor}}
                                className={"text-xs"}
                                key={index + column.data.color}
                            >
                                {props.program?.currency?.symbol}/{context.wordUnit('ha', 'lb')}
                            </th>
                        })}

                            {fullMode
                                ? <>
                                    <th className="align-bottom bg-gray-300">{context.word('n')}</th>
                                    <th className="align-bottom bg-gray-300">{system === MeasurementUnit.METRIC ? context.word('p') : context.word('p205')}</th>
                                    <th className="align-bottom bg-gray-300">{system === MeasurementUnit.METRIC ? context.word('k') : context.word('k20')}</th>
                                    <th className="align-bottom bg-gray-300">{context.word('ca')}</th>
                                    <th className="align-bottom bg-gray-300">{context.word('mg')}</th>
                                    <th className="align-bottom bg-gray-300">{context.word('s')}</th>

                                    <th className="align-bottom bg-gray-400">{context.word('fe')}</th>
                                    <th className="align-bottom bg-gray-400">{context.word('zn')}</th>
                                    <th className="align-bottom bg-gray-400">{context.word('b')}</th>
                                    <th className="align-bottom bg-gray-400">{context.word('mn')}</th>
                                    <th className="align-bottom bg-gray-400">{context.word('cu')}</th>
                                </>
                                : <th></th>}

                        </tr>
                    </thead>

                    <tbody>

                    <MemoRows
                        state={[state, stateDispatch]}
                        extractions={props.render?.extractions}
                        focusBlurCell={focusBlurCell}
                        getCellValue={getCellValue}
                        selectedCell={selectedCell}
                        program={props.program}
                        generateBy={props.currentGenerateBy}
                    />

                    {props.render?.totals()}

                    {currencyCells()}
                    
                    </tbody>
                </table>
            </div>
        </div>
    )
}

// from excel rows by \n, columns by \t
function formatClipboard(clipboard: string): string[][] {
    return clipboard.split('\n').map(r => r.split('\t'))
}

const Rows: React.FC<{
    state: [PlayFieldState, Dispatch<PlayFieldStateAction>];
    extractions?: (rowIndex: number) => React.ReactNode;
    selectedCell: CellPosition | null;

    getCellValue: (row: number, col: number) => string;
    focusBlurCell: (state: 'focus' | 'blur', col: number, row: number) => void;
    program?: PlayFieldProgram
    generateBy: GenerateBy
}> = (props) => {
    
    const [state, stateDispatch] = props.state
    const context = useContext(AppContext)
    const confirmation = useWarningState<number>(-1)
    
    const columns = state.calculations.pricingColumns;

    const rowDrag = useDrag<number>('drag-product', (target, destination) => stateDispatch({
        type: 'moveRow',
        fromIndex: target,
        toIndex: destination,
    }))
    
    function removeRow(rowIndex: number) {
        const name = state.data.rows[rowIndex]?.desc
        confirmation.show(`${context.word('sure_delete')} ${name}?`, rowIndex)
    }

    useEffect(() => {
        if (props.generateBy === GenerateBy.Weekly) {
            state.data.rows.map((row, index) => {
                
            })
            stateDispatch({
                type: 'updateRows',
                rowUpdate: state.data.rows.map(r => ({
                    month: matchWeek(r.month)
                }))
            })
        } else if (props.generateBy === GenerateBy.Monthly) {
            stateDispatch({
                type: 'updateRows',
                rowUpdate: state.data.rows.map(r => ({
                    month: matchMonth(r.month)
                }))
            })
        }
    }, [props.generateBy])

    function removeRowConfirmed(rowIndex: number) {
        stateDispatch({
            type: 'removeRow',
            index: rowIndex
        })
    }

    function handlePaste(event: React.ClipboardEvent<HTMLInputElement>, column: number, row: number) {
        const text = event.clipboardData.getData('text')
        if (!text || text === '') {
            return
        }

        const data = formatClipboard(text).map(t => t.map(t => t.trim().replace(/(\r\n|\n|\r)/gm, "")))
        stateDispatch({type: 'paste', data, column, row})
        event.preventDefault()
    }

    function showInputDependingOnGenerateBy(row: Row, rowIndex: number) {
        if (props.generateBy === GenerateBy.None) {
            return <input className="w-full outline-none cursor-pointer bg-white"
                          value={row.month}
                          onPaste={e => handlePaste(e, -1, rowIndex)}
                          onFocus={event => event.target.select()}
                          onChange={e => stateDispatch({
                              type: 'updateRow',
                              index: rowIndex,
                              month: e.target.value
                          })
                          }/>
        } else if (props.generateBy === GenerateBy.Weekly) {
            // return input with weeks as options
            return <SelectStringNullable className={"w-full outline-none cursor-pointer bg-white"}
                                         options={weeks}
                                         textFunc={t => context.word(t)}
                                         valueFunc={v => v}
                                         value={matchWeek(row.month)}
                                         onChange={e => stateDispatch({
                                             type: 'updateRow',
                                             index: rowIndex,
                                             month: e ?? undefined
                                         })}/>
        }

        // return input with months as options
        return <SelectStringNullable
            className={"w-full outline-none cursor-pointer bg-white"}
            options={months}
            textFunc={t => context.word(t)}
            valueFunc={v => v}
            value={matchMonth(row.month)}
            onChange={e => stateDispatch({
                type: 'updateRow',
                index: rowIndex,
                month: e ?? undefined
            })}/>
    }

    return <>
        <Warning state={confirmation} onYes={removeRowConfirmed}/>
        {state.data.rows.map((row, rowIndex) =>
            <tr key={row.index}
                {...rowDrag.targetAndDest(rowIndex)}
                className={classNames(
                    rowDrag.currentDrag !== null && rowDrag.isOver(rowIndex) && rowDrag.currentDrag < rowIndex ? 'border-b-4 border-gray-500' : '',
                    rowDrag.currentDrag !== null && rowDrag.isOver(rowIndex) && rowDrag.currentDrag > rowIndex ? 'border-t-4 border-gray-500' : '',
                    rowDrag.currentDrag === rowIndex ? 'opacity-50' : ''
                )}
            >
                <td className="flex border bg-white text-gray-800 whitespace-nowrap">
                    <div >
                        <Bars2Icon className="w-7 h-4 text-gray-500 cursor-pointer inline-block"/> 
                    </div>
                    <div onClick={() => removeRow(rowIndex)}
                         className="text-red-500 mx-1 cursor-pointer inline-block">X
                    </div>
                    <input
                        className="flex-1 outline-none cursor-pointer bg-white w-full"
                        onPaste={e => handlePaste(e, -2, rowIndex)}
                        onFocus={event => event.target.select()}
                        value={row.desc}
                        onChange={e => stateDispatch({
                            type: 'updateRow',
                            index: rowIndex,
                            desc: e.target.value
                        })}/>
                </td>
                <td className="border-t border-l border-b bg-white text-gray-800">
                    {
                        showInputDependingOnGenerateBy(row, rowIndex)
                    }
                </td>
                {columns.map((column, index) => {
                        if (column.type == 'column') {
                            const colindex = column.index;
                            return <td key={column.data.key}
                                       style={{
                                           backgroundColor: cellColor({
                                               x: colindex,
                                               y: rowIndex
                                           }, state.data.cells),
                                       }}
                                       className={classNames(borderStyle({
                                           x: colindex,
                                           y: rowIndex
                                       }, props.selectedCell))}>
                                <div className={"relative flex"}>
                                    <input type="text"
                                           style={{
                                               backgroundColor: cellColor({
                                                   x: colindex,
                                                   y: rowIndex
                                               }, state.data.cells),
                                           }}
                                           className="playfield-input text-xs relative outline-none w-full"
                                           onPaste={e => handlePaste(e, colindex, rowIndex)}
                                           value={props.getCellValue(rowIndex, colindex)}
                                           onInput={e => stateDispatch({
                                               type: 'setCellValue',
                                               value: (e.target as HTMLInputElement).value,
                                               row: rowIndex,
                                               column: colindex
                                           })}
                                           onFocus={(event) => {
                                               props.focusBlurCell('focus', colindex, rowIndex)
                                               event.target.select()
                                           }}
                                           onBlur={(event) => {
                                               const relatedTarget = event.relatedTarget;

                                               // Check if relatedTarget is part of the ribbon or another specific element
                                               if (relatedTarget && relatedTarget.classList.contains('color-picker')) {
                                                   return; // Do nothing if the blur was due to a ribbon selection
                                               }
                                               
                                               props.focusBlurCell('blur', colindex, rowIndex)
                                           }}
                                    />
                                    {
                                        rowIndex === props.selectedCell?.y && colindex === props.selectedCell?.x
                                            ? <input className='h-5 bg-white border color-picker'
                                                     type="color"
                                                     list=""
                                                     defaultValue={'#EDF2F7'}
                                                     value={cellColor({
                                                         x: colindex,
                                                         y: rowIndex
                                                     }, state.data.cells)}
                                                     onClick={e => {

                                                     }}
                                                     onChange={e => {
                                                         stateDispatch({
                                                             type: 'updateCellColor',
                                                             color: (e.target as HTMLInputElement).value,
                                                             row: rowIndex,
                                                             column: colindex
                                                         })
                                                     }}
                                                     onBlur={e => {

                                                     }}
                                            />
                                            : null
                                    }
                                </div>

                                {/* <div className="text-xs text-gray-500 bg-white"><FormatValue value={state.getCellStandard(rowIndex, colindex).metric}/></div> */}
                                {/* <div className="text-xs text-gray-500 bg-white"><FormatValue value={state.getCellStandard(rowIndex, colindex).imperial}/> Lb/Ac</div> */}
                            </td>
                        }
                    // don't render the pricing col if the pricingModule is disabled
                    if (!props.program?.pricingModule) return null

                    return <td key={column.data.color + index} className={""}
                               style={{backgroundColor: column.data.color, color: column.data.textColor}}>
                        <div className="flex justify-between items-center px-1">
                            <div className={"pr-1"}>{props.program.currency?.symbol}</div>
                            <div
                                className={""}>{getValue(state.data.system, column.data.rows[rowIndex]!).toFixed(2)}</div>
                        </div>
                        </td>;
                })}

                {props.extractions ? props.extractions(rowIndex) : <td></td>}

            </tr>
        )}
        <tr>
        <td colSpan={2}>
                        <div onClick={() => stateDispatch({
                            type: 'addRow',
                            desc: '',
                            month: ''
                        })}
                               className="w-8 h-8 text-xl rounded-full text-white text-center cursor-pointer bg-primary-500 shadow ho]ver:bg-primary-600">+
                        </div>
                {/*}*/}
            </td>
            <td colSpan={99}/>
        </tr>
    </>
}

const MemoRows = React.memo(Rows, (prevProps, nextProps) => {
    const prev = prevProps.state[0].data
    const next = nextProps.state[0].data

    return prevProps.selectedCell === nextProps.selectedCell && prev.rows === next.rows && prev.cells === next.cells && prevProps.generateBy === nextProps.generateBy
    // && arrayCompare(prev.columns, next.columns, (a, b) => a.product == b.product && a.unit == b.unit)
})

export default PlayFieldTemplate
