import React, {useContext} from "react";
import AppContext from "../../appContext";

const Notes: React.FC<{
    notes: string
    setNotes: (notes: string) => void
}> = (props) => {
    const context = useContext(AppContext)

    return <div className="flex-1 flex-col ml-2">
        <div>{context.word("notes")}:</div>
        <textarea className="w-full border-2 h-24 outline-none"
                  placeholder="Write notes here.."
                  value={props.notes}
                  onChange={v => props.setNotes(v.target.value)}>
        </textarea>
    </div>
}

export default Notes